export const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME;

export const currency = process.env.GATSBY_CURRENCY;
export const country = "Scotland";
export const defaultArea = process.env.GATSBY_DEFAULT_AREA ? process.env.GATSBY_DEFAULT_AREA : "London";
export const defaultArea_Slug = defaultArea.toLowerCase().replace(/ /g,'-');

export const defaultArea_Newhomes = process.env.GATSBY_DEFAULT_AREA_NEWHOMES ? process.env.GATSBY_DEFAULT_AREA_NEWHOMES : "London";
export const defaultArea_Newhomes_Slug = defaultArea_Newhomes.toLowerCase().replace(/ /g,'-');

export const site_name = process.env.GATSBY_SITE_NAME;
export const Bugherd_API_Key = "pomqhu8dp8gq30hoglt0ha";

export const site_bviewing_agent_id = "RFR-Stonehouse";

export const footerVatDetails = "Lomond is a group of leading Lettings and Sales Agents across the UK.<br />Lomond is the trading name of Chianti Holdings Ltd.<br />Registered office address: 70 St. Mary Axe, London, EC3A 8BE.<br />Registered in England and Wales. Registered Company Number: 13075482.";

export const Mail_Vars = {
    fb_link: "https://www.facebook.com/stonehouseaberdeen/",
    tw_link: "",
    company_phone: "01224 466266",
    available_time: "We're available from 9- 5pm Monday to Friday.",
    address: "Helena House, Troy Mills, Troy Road, Horsforth, West Yorkshire"
}

// export const PageLinks = {
//     about: "about-stonehouse",
//     general_enquiry: "general-enquiry",
//     news_insight: `about-stonehouse/insights`,
//     branch_finder: "about-stonehouse/branch-finder",
//     area_cover: "about-stonehouse/areas-we-cover",
//     branch_contact: "contact-us",
//     team_contact: "contact-us",
//     teams: "about-stonehouse/meet-the-team",
//     request_valuation: "property-valuation",
//     privacy_policy: "privacy-policy",
//     book_a_viewing: "book-a-viewing",
//     book_a_valuation: "property-valuation/home-visit-valuation",
//     send_to_friend: "send-to-friend",
//     property_to_rent: "property/to-rent",
//     property_for_sale: "property/for-sale",
//     reviews: "about-stonehouse/customer-reviews",
//     property_management: "landlords/property-management",
//     financial_services: `financial-services`
// }

export const PropertySearchURL = {
    residential: {
        sales:"/property/for-sale/",
        lettings:"/property/to-rent/",
        newhomes: "/new-homes/for-sale/"
    },
    commercial: {
        sales:"/commercial/for-sale/",
        lettings:"/commercial/to-rent/",
    },
    auction: {
        sales:"/auctions/for-sale/"
    }
}

export const PropertyDetailsURL = {
    residential: {
        sales:"/property-for-sale/",
        lettings:"/property-to-rent/",
        newhomes:"/new-homes-for-sale/"
    },
    commercial: {
        sales:"/commercial-for-sale/",
        lettings:"/commercial-to-rent/",
    },
    auction: {
        sales:"/auction-for-sale/"
    }
}

export const SearchTitle = {
    residential: {
        sales:"Properties for sale",
        lettings:"Properties to rent",
        newhomes:"New developments"
    },
    commercial: {
        sales:"Commercial properties for sale",
        lettings:"Commercial properties to rent",
    },
    auction: {
        sales:"Auction properties for sale"
    }
}

export const PopularSearch_Areas = [ 
    { value: "aberdeen", label: "Aberdeen" },
    { value: "aberdeenshire", label: "Aberdeenshire" },
    { value: "kemnay", label: "Kemnay" },
    { value: "inverurie", label: "Inverurie" }   
]

export const PopularSearch_Types = [ 
    { value: "house", label: "Houses" },
    { value: "apartment", label: "Apartments" },
    { value: "bungalow", label: "Bungalows" },
    { value: "maisonette", label: "Maisonettes" }   
]

export const sortByList = [ 
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
    { value: "HighestPrice", label: "Highest Price" },
    { value: "LowestPrice", label: "Lowest Price" }   
]

export const SearchType = {
    sales:"Buy",
    lettings:"Rent",
    newhomes:"New homes"
}

export const FurnishedType = [    
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'part-furnished', label: 'Part furnished' },
]

export const FormsSubject = {
    book_a_valuation_sell:{
        admin_subject:"New Vendor Enquiry",
        user_subject:"Your recent enquiry"
    },
    book_a_valuation_let:{
        admin_subject:"New Landlord Enquiry",
        user_subject:"Your recent enquiry"
    },
    book_a_viewing:{
        admin_subject:"Book a Viewing",
        user_subject:"Your viewing request"
    },
    contact_branch:{
        admin_subject:"Contact",
        user_subject:"Your recent enquiry"
    },
    contact_staff:{
        admin_subject:"Contact",
        user_subject:"Your recent enquiry"
    },
    get_in_touch:{
        admin_subject:"Contact",
        user_subject:"Your recent enquiry"
    },
    newsletter:{ 
        admin_subject:"Newsletter Subscription",
        user_subject:"Thank you for subscribing"
    },
    instant_valuation:{
        admin_subject:"Valuation Request",
        user_subject:"Your valuation request"
    },
    send_to_friend:{
        admin_subject:"Send to a friend",
        user_subject:"Send to a friend"
    }
}